import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				CityCar Rentals
			</title>
			<meta name={"description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:title"} content={"CityCar Rentals"} />
			<meta property={"og:description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3">
				Abu Dhabi Plaza, Syganak St{" "}
				<br />
				60/5, Astana 010000, Kazakhstan
			</Override>
			<Override slot="link2">
				+77001170101
			</Override>
			<Override slot="box1" />
		</Components.Header>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-16">
			<Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
			<Box
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 50% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						src="https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11:27:28.697Z"
						position="absolute"
						width="100%"
						bottom="-180px"
						right="0px"
						min-height="100%"
						object-fit="cover"
						display="block"
						top="auto"
						left="auto"
						height="746.859px"
						srcSet="https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-5.jpg?v=2024-06-14T11%3A27%3A28.697Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				justify-content="space-between"
				width="58%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				lg-justify-content="center"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box display="flex" flex-direction="column" md-width="100%">
					<Text
						font="--headline2"
						lg-text-align="center"
						sm-font="--headline3"
						sm-margin="0px 0px 20px 0px"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						md-text-align="left"
					>
						Добро пожаловать на CityCar Rentals
					</Text>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
				width="40%"
				flex-direction="row"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-padding="0px 16px 16px 16px"
			>
				<Box display="flex" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						lg-text-align="center"
						md-text-align="left"
					>
						Отправляйтесь в путешествие, где адреналин сочетается с изысканностью в CityCar Rentals, вашем главном источнике современных масл-каров. Наш автопарк создан для тех, кто жаждет азарта и незабываемого опыта вождения. Независимо от того, планируете ли вы живописное путешествие или хотите незабываемой ночью, наши мышечные автомобили обещают сделать каждое мгновение волнующим.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="150px 0 150px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-4.jpg?v=2024-06-17T11:31:25.100Z) center/cover repeat scroll padding-box" sm-padding="80px 0 80px 0" quarkly-title="Product-7">
			<Box min-width="100px" min-height="100px" display="flex" justify-content="flex-end">
				<Box
					min-width="100px"
					min-height="100px"
					width="900px"
					background="rgba(255, 255, 255, 0.9)"
					padding="50px 35px 50px 35px"
					display="flex"
					align-items="center"
					flex-direction="column"
					md-padding="35px 35px 35px 35px"
					border-radius="30px"
					margin="0px auto 0px auto"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3" text-align="center">
						CityCar Rentals – это не просто аренда автомобилей, это создание образа жизни.
					</Text>
					<Text margin="0px 0px 35px 0px" text-align="center" font="normal 400 18px/1.5 --fontFamily-sansHelvetica">
						Каждый масл-кар в нашей коллекции сочетает сильную мощность с изящной эстетикой, что гарантирует, что ваша аренда будет просто поразительной. От рева двигателей до гладкой полированной обработки, наши автомобили являются свидетельством автомобильно
					</Text>
					<Button
						background="--color-darkL1"
						padding="12px 30px 12px 30px"
						border-radius="25px"
						href="/menu"
						type="link"
						text-decoration-line="initial"
					>
						Услуги
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				align-self="center"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Почему CityCar?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					В CityCar Rentals мы выходим за рамки оснований, чтобы обеспечить исключительный опыт аренды с несколькими ключевыми преимуществами:
					<br />
					<br />
					Прекрасное состояние: Каждое транспортное средство тщательно обслуживается и детализируется, предлагая вам безупречное состояние авто в автосалоне с ощущением гоночной трассы.
					<br />
					<br />
					Индивидуальные пакеты: Мы предлагаем индивидуальные пакеты аренды, которые удовлетворяют ваши конкретные потребности, будь то день, выходные или особое событие.
					<br />
					<br />
					Совет эксперта: Наш опытный персонал сам является энтузиастом и может предоставить подробную информацию о каждой модели, помогая вам выбрать идеальный автомобиль для вашего приключения.
					<br />
					<br />
					Стратегическое расположение: Легко доступно, наше расположение позволяет быстро получить и вернуть, что делает вашу аренду легкой и удобной.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				height="100%"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					max-height="600px"
				>
					<Image
						width="100%"
						src="https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11:31:25.098Z"
						object-fit="cover"
						display="block"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66701dce8d4a0c0020308b14/images/1-2.jpg?v=2024-06-17T11%3A31%3A25.098Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="link" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});